<template>
  <div class="settings__f76-names">
    <div class="tiny__content">
      <p class="tiny__content__title">ФОРМИ 76 НАИМЕНОВАНИЯ</p>
      <hr class="line" />
      <div class="row" v-for="f in f76Names" :key="f._id">
        <Input v-model="f.name" label="" />
      </div>
      <div class="tiny__content__buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading"
          >Запиши</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/inputs/Input";
import Button from "@/components/Button";
import Loading from "@/components/Loading";
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  data() {
    return {
      loading: false,
      success: undefined,
      f76Names: [],
    };
  },
  methods: {
    cancel() {
      this.$router.push("/vacations");
    },
    getF76Names() {
      this.f76Names = [];
      this.$apiService.get("/f76-names").then((res) => {
        this.f76Names = res.data;
      });
    },
    isValid() {
      for (let f of this.f76Names) {
        if (!f.name) {
          this.$snotify.error("Всички полета са задължителни!");
          return false;
        }
      }
      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;

        this.$apiService
          .post("/f76-names", this.f76Names)
          .then(() => {
            this.success = true;
            this.$snotify.success("Имената са запазени успешно");
          })
          .catch((err) => {
            this.success = false;
            console.log(err);
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.success = undefined;
            }, 1000);
          });
      }
    },
  },
  mounted() {
    this.getF76Names();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.line {
  margin-bottom: 2rem;
}
.row {
  width: 100%;
  margin-top: 5px;
  .input-wrapper {
    width: 100%;
  }
}
.tiny__content__buttons {
  align-items: center;
}
</style>
